import { AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BOIRFormFilingInformation } from "./BOIRFormFilingInformation";
import { BOIRFormReportingCompany } from "./BOIRFormReportingCompany";
import { BOIRFormSubmitterInfo } from "./BOIRFormSubmitterInfo";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { BOIRFormData, getFormDefaults } from "./BOIRFormDefaults";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledAccordion } from "../../../../components/StyledAccordion";
import { BOIRFormPersons } from "./BOIRFormPersons";
import { BOIRCompanyApplicant } from "./BOIRCompanyApplicant";
import { BOIRBeneficialOwner } from "./BOIRBeneficialOwner";
import { PaymentModal } from "../../../../components/PaymentModal";
import { checkPaymentStatus, completePayment, downloadBOIRFile } from "./utils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { AuditActionType, logAuditAction } from "../../../../services/auditService";

interface BOIRFormProps {
  formData: BOIRFormData;
  initiateSubmission: (data: BOIRFormData) => void;
}

export const BOIRForm: React.FC<BOIRFormProps> = ({ formData, initiateSubmission }) => {
  const methods = useForm({
    defaultValues: formData || getFormDefaults(),
  });

  const {
    formState: { errors },
  } = methods;
  const hasSubmitterErrors = Object.keys(errors?.submitterInfo || {}).length > 0;
  const hasFilingErrors = Object.keys(errors?.filingInformation || {}).length > 0;
  const hasReportingCompanyErrors = Object.keys(errors?.reportingCompany || {}).length > 0;
  const hasCompanyApplicantsErrors = Object.keys(errors?.companyApplicants || {}).length > 0;
  const hasBeneficialOwnersErrors = Object.keys(errors?.beneficialOwners || {}).length > 0;

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: BOIRFormData) => {
    setIsSubmitting(true);
    if (!(await checkPaymentStatus())) {
      setShowPaymentModal(true);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    initiateSubmission(data);
  };

  const onError = (errors: any) => {
    console.log(errors);
    console.log(methods.getValues());
  };

  const yearFormed = methods.watch("reportingCompany.yearFormed");
  const typeOfFiling = methods.watch("filingInformation.typeOfFiling");
  const yearFormedNumber = Number(yearFormed);

  const [expandedSections, setExpandedSections] = useState({
    reportingCompany: false,
    companyApplicants: false,
    beneficialOwners: false,
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (yearFormed) {
        methods.clearErrors("companyApplicants");
      }
    }, 300);
    if (yearFormed && yearFormedNumber < 2024) {
      setExpandedSections((prev) => ({ ...prev, companyApplicants: false }));
    }
    return () => clearTimeout(timeoutId);
  }, [yearFormed, methods]);

  useEffect(() => {
    if (typeOfFiling === "exempt") {
      methods.clearErrors("reportingCompany");
      methods.clearErrors("companyApplicants");
      methods.clearErrors("beneficialOwners");
      setExpandedSections((prev) => ({
        ...prev,
        reportingCompany: false,
        companyApplicants: false,
        beneficialOwners: false,
      }));
    }
  }, [typeOfFiling, methods]);

  const handleAccordionChange = (section: string) => (event: any, isExpanded: boolean) => {
    setExpandedSections((prev) => ({ ...prev, [section]: isExpanded }));
  };

  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        flexDirection={{ xs: "column" }}
        alignItems={"center"}
        gap={6}
        boxShadow={2}
        padding={4}
        bgcolor={"background.paper"}
        borderRadius={3}
      >
        <Typography variant="h4">Beneficial Ownership Information Report</Typography>

        <Box width={"100%"} display={"flex"} flexDirection={{ xs: "column" }} gap={1}>
          <StyledAccordion className={hasSubmitterErrors ? "error" : ""}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasSubmitterErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>Submitter Information</Typography>
              {hasSubmitterErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <BOIRFormSubmitterInfo />
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion className={hasFilingErrors ? "error" : ""}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasFilingErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>Filing Information</Typography>
              {hasFilingErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <BOIRFormFilingInformation />
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.reportingCompany}
            onChange={handleAccordionChange("reportingCompany")}
            className={hasReportingCompanyErrors ? "error" : ""}
            disabled={typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasReportingCompanyErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part I. Reporting Company Information
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No reporting company information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>

              {hasReportingCompanyErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {typeOfFiling !== "exempt" && <BOIRFormReportingCompany />}
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.companyApplicants}
            onChange={handleAccordionChange("companyApplicants")}
            className={hasCompanyApplicantsErrors ? "error" : ""}
            disabled={(yearFormed && yearFormedNumber < 2024) || typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasCompanyApplicantsErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part II. Company Applicant(s) Information
                {!(typeOfFiling === "exempt") && yearFormed && yearFormedNumber < 2024 && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: reporting company was formed before 2024, no company applicant information
                    is required
                  </Typography>
                )}
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No company applicant information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>
              {hasCompanyApplicantsErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {(yearFormed === "" || yearFormedNumber >= 2024) && typeOfFiling !== "exempt" && (
                <BOIRFormPersons
                  name="companyApplicants"
                  title="Company Applicant"
                  checkboxLabel="I know of another person who was involved in the formation filing of this reporting company"
                  addButtonLabel="Add Applicant"
                  ItemComponent={BOIRCompanyApplicant}
                />
              )}
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.beneficialOwners}
            onChange={handleAccordionChange("beneficialOwners")}
            className={hasBeneficialOwnersErrors ? "error" : ""}
            disabled={typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasBeneficialOwnersErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part III. Beneficial Owner(s) Information
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No beneficial owner information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>
              {hasBeneficialOwnersErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {typeOfFiling !== "exempt" && (
                <BOIRFormPersons
                  name="beneficialOwners"
                  title="Beneficial Owner"
                  checkboxLabel="I know of another person who may exercise substantial control over this reporting company or may own at least 25% of the company"
                  addButtonLabel="Add Beneficial Owner"
                  ItemComponent={BOIRBeneficialOwner}
                />
              )}
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      </Box>
      <PaymentModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onComplete={() => completePayment()}
      />
      <Box display="flex" justifyContent="space-between" width="100%" mt={4}>
        <Box
          display="flex"
          flexDirection={{ xs: "column" }}
          boxShadow={2}
          padding={2}
          bgcolor={"background.paper"}
          borderRadius={3}
          maxWidth={400}
        >
          <Typography variant="subtitle1" fontWeight={500} gutterBottom>
            Save Your Progress
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Download your form data to save your progress. You can resume later by uploading the
            .BOIR or BOIR.zip file from the home page.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={async () => {
              await logAuditAction(AuditActionType.BOIR_FILING_DATA_DOWNLOAD);
              downloadBOIRFile(methods.getValues());
            }}
            disabled={isSubmitting}
            startIcon={<FileDownloadOutlinedIcon />}
          >
            Download
          </Button>
        </Box>
        <Button
          variant="contained"
          size="large"
          onClick={methods.handleSubmit(onSubmit, onError)}
          sx={{
            minWidth: "120px",
            height: "fit-content",
            "& .MuiCircularProgress-root": {
              marginLeft: 1,
            },
          }}
        >
          Continue
          {isSubmitting && <CircularProgress size={20} color="inherit" />}
        </Button>
      </Box>
    </FormProvider>
  );
};
