import { auth, db } from "../../../../auth/auth";
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { BOIRFormData } from "./BOIRFormDefaults";
import JSZip from "jszip";

export const checkPaymentStatus = async () => {
  try {
    const firebaseUser = auth.currentUser;
    if (firebaseUser) {
      const paymentQuery = query(
        collection(db, "payments"),
        where("userId", "==", firebaseUser.uid)
      );
      const paymentSnap = await getDocs(paymentQuery);
      return !paymentSnap.empty;
    }
  } catch (error) {
    console.error("Error checking payment status:", error);
    throw error;
  }
};

export const completePayment = async () => {
  const firebaseUser = auth.currentUser;
  if (firebaseUser) {
    try {
      await addDoc(collection(db, "payments"), {
        userId: firebaseUser.uid,
        timestamp: serverTimestamp(),
        status: "completed",
        amount: 99,
      });
    } catch (error) {
      console.error("Error saving payment:", error);
    }
  }
};

export const formatFinCENId = (value: string) => {
  const cleaned = value.replace(/\D/g, "");

  const match = cleaned.match(/^(\d{0,4})(\d{0,4})(\d{0,4})$/);

  if (!match) return "";

  return [match[1], match[2], match[3]].filter(Boolean).join("-");
};

export const downloadBOIRFile = async (formData: BOIRFormData) => {
  const jsonString = JSON.stringify(formData, null, 2);
  const encodedData = btoa(unescape(encodeURIComponent(jsonString)));

  const hasFiles =
    formData.companyApplicants.some(
      (applicant) => applicant.document && applicant.document.length > 0
    ) || formData.beneficialOwners.some((owner) => owner.document && owner.document.length > 0);

  if (hasFiles) {
    const zip = new JSZip();

    zip.file(getFileNameWithDate("formData.BOIR"), encodedData);

    formData.companyApplicants.forEach((applicant, index) => {
      if (applicant.document && applicant.document.length > 0) {
        applicant.document.forEach((file) => {
          zip.file(`company_applicant_${index + 1}_${file.name}`, file);
        });
      }
    });

    formData.beneficialOwners.forEach((owner, index) => {
      if (owner.document && owner.document.length > 0) {
        owner.document.forEach((file) => {
          zip.file(`beneficial_owner_${index + 1}_${file.name}`, file);
        });
      }
    });

    const content = await zip.generateAsync({ type: "blob" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = getFileNameWithDate("BOIR.zip");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const blob = new Blob([encodedData], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = getFileNameWithDate("formData.BOIR");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getFileNameWithTimeStamp = (fileName: string) => {
  return encodeURIComponent(new Date().getTime() + "-" + fileName);
};

export const getFileNameWithDate = (fileName: string) => {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${day}${month}${year}-${hour}${minute}${seconds}`;

  return encodeURIComponent(`${formattedDate}-${fileName}`);
};

export const uploadAndDecodeBOIRFile = async (file: File): Promise<BOIRFormData> => {
  if (file.name.endsWith(".zip")) {
    // Handle zipped file
    const zip = new JSZip();
    const zipContents = await zip.loadAsync(file);

    const formDataFile = Object.values(zipContents.files).find((f) => f.name.endsWith(".BOIR"));
    if (!formDataFile) {
      throw new Error("No .BOIR file found in the zip archive");
    }

    const encodedData = await formDataFile.async("string");
    const jsonString = decodeURIComponent(escape(atob(encodedData)));
    const formData: BOIRFormData = JSON.parse(jsonString);

    // Process other files in the zip
    for (const [filename, zipFile] of Object.entries(zipContents.files)) {
      if (filename.startsWith("company_applicant_")) {
        const [, index, name] = filename.match(/company_applicant_(\d+)_(.+)/) || [];
        if (index && name) {
          const fileContent = await zipFile.async("blob");
          const file = new File([fileContent], name);
          formData.companyApplicants[parseInt(index) - 1].document = [file];
        }
      } else if (filename.startsWith("beneficial_owner_")) {
        const [, index, name] = filename.match(/beneficial_owner_(\d+)_(.+)/) || [];
        if (index && name) {
          const fileContent = await zipFile.async("blob");
          const file = new File([fileContent], name);
          formData.beneficialOwners[parseInt(index) - 1].document = [file];
        }
      }
    }

    return formData;
  } else {
    // Handle non-zipped file
    const encodedData = await file.text();
    const jsonString = decodeURIComponent(escape(atob(encodedData)));
    return JSON.parse(jsonString);
  }
};
