import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { StripePaymentComponent } from "../features/stripe/StripePaymentComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose, onComplete }) => {
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Complete Payment to Continue
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <StripePaymentComponent
          onComplete={() => {
            setPaymentCompleted(true);
            onComplete();
          }}
        />

        {paymentCompleted && (
          <Box
            sx={{
              mt: 3,
              p: 2,
              bgcolor: "#f0f7f0",
              borderRadius: 2,
              border: "1px solid #4caf50",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <CheckCircleIcon color="success" />
            <div>
              <Typography variant="subtitle1" color="success.main" fontWeight={500}>
                Payment Successful
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={0.5}>
                {`Thank you for your payment. Review again your report and click on Continue button if it is ready for filing.`}
              </Typography>
            </div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
