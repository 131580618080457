import React, { useState } from "react";
import { Control, useFieldArray, useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { personDefaults } from "./BOIRFormDefaults";

interface BOIRPersonsProps {
  name: string;
  title: string;
  checkboxLabel: string;
  addButtonLabel: string;
  ItemComponent: React.ComponentType<{ index: number }>;
}

export const BOIRFormPersons: React.FC<BOIRPersonsProps> = ({
  name,
  title,
  checkboxLabel,
  addButtonLabel,
  ItemComponent,
}) => {
  const { control } = useFormContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasAdditionalItem, setHasAdditionalItem] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: control as Control,
    name,
  });

  const handleAddItem = () => {
    append(personDefaults);
    setCurrentPage(fields.length + 1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
    if (currentPage > fields.length - 1) {
      setCurrentPage(Math.max(1, fields.length - 1));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasAdditionalItem(e.target.checked);
    if (!e.target.checked) {
      for (let i = fields.length - 1; i > 0; i--) {
        remove(i);
      }
      setCurrentPage(1);
    }
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">
          {title} {currentPage} of {fields.length}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox checked={hasAdditionalItem} onChange={handleCheckboxChange} size="small" />
          }
          label={
            <Typography variant="body2" sx={{ maxWidth: 400 }}>
              {checkboxLabel}
            </Typography>
          }
        />
        {hasAdditionalItem && (
          <Button onClick={handleAddItem} variant="contained" size="small" startIcon={<AddIcon />}>
            {addButtonLabel}
          </Button>
        )}
      </Stack>
      {fields.length > 1 && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <Pagination
            count={fields.length}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      )}

      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{ display: currentPage === index + 1 ? "block" : "none", position: "relative" }}
        >
          <ItemComponent index={index} />
          {fields.length > 1 && (
            <IconButton
              onClick={() => handleRemoveItem(index)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      {fields.length > 1 && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <Pagination
            count={fields.length}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      )}
    </Box>
  );
};
