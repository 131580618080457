import { MenuItem, Select, SelectProps } from "@mui/material";
import React from "react";
import { documentTypeIds, identifyingDocuments } from "../../../../constants/documentTypes";

type BOIRFormDocumentTypeComponentProps = SelectProps & {
  error?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  value?: string | number;
};

export const BOIRFormIdentifyingDocumentComponent = React.forwardRef<
  HTMLSelectElement,
  BOIRFormDocumentTypeComponentProps
>(({ error, size, disabled, value = "", ...props }, ref) => {
  return (
    <Select ref={ref} error={error} size={size} disabled={disabled} value={value} {...props}>
      {identifyingDocuments.map((type) => (
        <MenuItem key={type} value={type}>
          {documentTypeIds[type] || "Select document type"}
        </MenuItem>
      ))}
    </Select>
  );
});

// Add a display name for better debugging
BOIRFormIdentifyingDocumentComponent.displayName = "BOIRFormIdentifyingDocumentComponent";
