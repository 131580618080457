import { createSelector } from "@reduxjs/toolkit";
import User from "../../models/user/User";
import { RootState } from "../../app/store";

export const selectUserObject = (state: RootState) => state.user.user;

export const selectUserInstance = createSelector([selectUserObject], (userObject) => {
  return new User(
    userObject.firebaseUid,
    userObject.hasCompletedSurvey,
    userObject.payment_complete,
    userObject.firstName,
    userObject.lastName,
    userObject.email,
    userObject.phoneNumber
  );
});
