import { MenuItem, Select, SelectProps } from "@mui/material";
import React from "react";
import { stateISOCodes, stateNames } from "../../../../constants/states";

type BOIRFormStatesComponentProps = SelectProps & {
  error?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  value?: string;
};

export const BOIRFormStatesComponent = React.forwardRef<
  HTMLSelectElement,
  BOIRFormStatesComponentProps
>(({ error, size, disabled, value = "", ...props }, ref) => {
  return (
    <Select ref={ref} error={error} size={size} disabled={disabled} value={value} {...props}>
      {stateISOCodes.map((state) => (
        <MenuItem key={state} value={state}>
          {stateNames[state] || "Select a state"}
        </MenuItem>
      ))}
    </Select>
  );
});

// Add a display name for better debugging
BOIRFormStatesComponent.displayName = "BOIRFormStatesComponent";
