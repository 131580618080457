export interface Person {
  finCENId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  dateOfBirth: string;
  gender: string;
  addressType: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  documentType: number | string;
  documentNumber: string;
  documentCountry: string;
  documentState: string;
  documentFileName: string;
  document: File[] | null;
}

export interface SubmitterInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export interface FilingInformation {
  typeOfFiling: string;
  taxIdType: string;
  taxIdNumber: string;
  legalName: string;
  jurisdiction: string;
  datePrepared: string;
}

export interface ReportingCompany {
  legalName: string;
  alternateNames: string[];
  address: string;
  city: string;
  territory: string;
  state: string;
  zipCode: string;
  country: string;
  phone: string;
  email: string;
  yearFormed: string;
  taxIdType: string;
  taxIdNumber: string;
}

export interface BOIRFormData {
  submitterInfo: SubmitterInfo;
  filingInformation: FilingInformation;
  reportingCompany: ReportingCompany;
  companyApplicants: Person[];
  beneficialOwners: Person[];
}

export const submitterInfoDefaults: SubmitterInfo = {
  firstName: "",
  lastName: "",
  email: "",
};

export const personDefaults: Person = {
  finCENId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  suffix: "",
  dateOfBirth: "",
  gender: "",
  addressType: "residential",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  country: "US",
  documentType: "",
  documentNumber: "",
  documentCountry: "US",
  documentState: "",
  document: null,
  documentFileName: "",
};

export const filingInformationDefaults: FilingInformation = {
  typeOfFiling: "initial",
  taxIdType: "2",
  taxIdNumber: "",
  legalName: "",
  jurisdiction: "",
  datePrepared: new Date().toLocaleDateString(),
};

export const reportingCompanyDefaults: ReportingCompany = {
  legalName: "",
  alternateNames: [],
  address: "",
  city: "",
  territory: "",
  state: "",
  zipCode: "",
  country: "",
  phone: "",
  email: "",
  yearFormed: "",
  taxIdType: "2",
  taxIdNumber: "",
};

export const getFormDefaults = (): BOIRFormData => ({
  submitterInfo: submitterInfoDefaults,
  filingInformation: filingInformationDefaults,
  reportingCompany: reportingCompanyDefaults,
  companyApplicants: [personDefaults],
  beneficialOwners: [personDefaults],
});
