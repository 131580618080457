import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState, useEffect } from "react";
import { BOIRFormData } from "../boir-form/BOIRFormDefaults";
import { downloadBOIRFile } from "../boir-form/utils";
import { AuditActionType, logAuditAction } from "../../../../services/auditService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { fincenSelectors } from "../../../../features/fincenData/fincenSelector";

const emailMessage = (processId: string) => `
<h2 style="color: #2D3748; margin-bottom: 24px;">
BOIR Submission Tracking ID
</h2>
<p style="font-size: 16px; line-height: 1.6; margin-bottom: 16px;">
Hello!
</p>
<p style="font-size: 16px; line-height: 1.6;">
Found below is your official BOIR submission tracking ID.  
This tracking ID is your record of submission and will be used when communicating with FinCEN regarding your BOIR.
</p>
<p style="font-size: 16px; line-height: 1.6;">
Securely maintain your downloaded Licenz form for any necessary updates to your reporting status.
</p>
<p style="font-size: 16px; line-height: 1.6;">
Tracking ID: <strong>${processId}</strong>
</p>
`;

export const SubmissionStatusEmailSend = ({
  boirFormData,
  pdfBinary,
}: {
  boirFormData: BOIRFormData;
  pdfBinary: string;
}) => {
  const { idToken } = useSelector((state: RootState) => state.user.auth);
  const { processId } = useSelector(fincenSelectors.fincen);
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (emailSent) {
      timeoutId = setTimeout(() => {
        setEmailSent(false);
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [emailSent]);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography variant="h5" component="h2" gutterBottom fontWeight={600} align="center">
            Send to Email
          </Typography>

          <TextField
            fullWidth
            label="Email Address"
            type="email"
            placeholder="john.doe@gmail.com"
            value={email}
            size="small"
            onChange={(e) => setEmail(e.target.value)}
            error={!isValidEmail || !!emailError}
            helperText={emailError}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            border="1px solid #E0E0E0"
            borderRadius={2}
            bgcolor="#F5F5F5"
            p={2}
          >
            <Typography variant="body1" fontWeight={700} fontStyle="italic" textAlign="left">
              No attachments will be sent via email for security reasons.
            </Typography>
            <Typography variant="body1" textAlign="left">
              Please download your:
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography variant="body1" textAlign="left">
                (i) FinCEN submission transcript
              </Typography>
              <Typography variant="body1" textAlign="left">
                (ii) .BOIR file containing your complete filing information
              </Typography>
            </Box>
            <Typography variant="body1" textAlign="left">
              These important documents serve as your record of BOIR submission and should be stored
              in a secure location.
            </Typography>
            <Typography
              variant="body1"
              fontWeight={700}
              fontStyle="italic"
              textAlign="left"
              sx={{ textDecoration: "underline" }}
            >
              Licenz does not retain copies of these sensitive documents.
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} display="flex" gap={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={async () => {
                if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                  setEmailError("Invalid email address");
                  setIsValidEmail(false);
                  return;
                }
                setEmailError("");
                setIsValidEmail(true);
                setIsLoading(true);
                try {
                  const emailMessageWithProcessId = emailMessage(processId);
                  const response = await fetch(`${process.env.REACT_APP_FINSEND_API}/email/send`, {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${idToken}`,
                      "Content-Type": "application/json",
                      Accept: "*/*",
                      "Cache-Control": "no-cache",
                    },
                    body: JSON.stringify({
                      to: email,
                      subject: "BOIR Submission",
                      message: emailMessage(processId),
                    }),
                  });
                  if (response.ok) {
                    setEmailError("");
                    setEmailSent(true);
                  } else {
                    setEmailError(`Failed to send email: ${response.statusText}`);
                  }
                } catch (error) {
                  setEmailError(`Error sending email`);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              <EmailOutlinedIcon sx={{ mr: 1 }} />
              Send Email
              {isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                logAuditAction(AuditActionType.BOIR_FILING_DATA_DOWNLOAD);
                downloadBOIRFile(boirFormData);
              }}
            >
              <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
              Download
            </Button>
          </Box>
          {emailSent && (
            <Alert sx={{ minWidth: 400 }} severity="success">
              Email sent successfully
            </Alert>
          )}
        </Box>
      </Paper>
    </Container>
  );
};
