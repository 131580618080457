import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BOIRFormStatesComponent } from "./BOIRFormStatesComponent";
import { FileDropzone } from "../../../../components/FileDropzone";
import { BOIRFormIdentifyingDocumentComponent } from "./BOIRFormDocumentTypeComponent";
import { formatFinCENId } from "./utils";
import { ACCEPTED_FILE_TYPES_ATTACHMENTS } from "../../../../constants/acceptedFileTypes";
import React from "react";
import { BOIRFormData } from "./BOIRFormDefaults";

interface BOIRCompanyApplicantProps {
  index: number;
}

export const BOIRCompanyApplicant: React.FC<BOIRCompanyApplicantProps> = ({ index }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<BOIRFormData>();
  const applicantErrors = errors?.companyApplicants?.[index];
  const documentFiles = watch(`companyApplicants.${index}.document`) || [];
  const fincenId = watch(`companyApplicants.${index}.finCENId`);
  const hasFinCENId = !!fincenId;

  const handleFileChange = (files: File[] | null) => {
    setValue(`companyApplicants.${index}.document`, files);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box display="flex" alignItems="center" gap={0.5} sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Company Applicant FinCEN ID:</Typography>
        <Tooltip
          title={
            <Typography variant="body2">
              Reporting companies may report the FinCEN ID for a company applicant instead of the
              information.
              <br />
              Enter the FinCEN ID as a single text string.
              <br />
              If a FinCEN ID for the company applicant is not provided, information about the
              company applicant must be provided.
            </Typography>
          }
          placement="top"
          arrow
          sx={{ cursor: "pointer" }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Box>

      <FormGroup sx={{ gap: 1 }}>
        <Controller
          name={`companyApplicants.${index}.finCENId`}
          control={control}
          rules={{
            validate: (value) => {
              if (!value) return true; // Allow empty field
              return /^\d{12}$/.test(value) || "FinCEN ID must be 12 digits";
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="FinCEN ID"
              error={!!applicantErrors?.finCENId}
              helperText={applicantErrors?.finCENId?.message}
              inputProps={{
                maxLength: 14,
              }}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                field.onChange(numericValue);
              }}
              value={formatFinCENId(field.value)}
              inputMode="numeric"
            />
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Full legal name and date of birth:
        </Typography>

        <Controller
          name={`companyApplicants.${index}.lastName`}
          control={control}
          rules={{ required: !hasFinCENId && "Last name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Last name"
              error={!hasFinCENId && !!applicantErrors?.lastName}
              helperText={!hasFinCENId && applicantErrors?.lastName?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.firstName`}
          control={control}
          rules={{ required: !hasFinCENId && "First name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="First name"
              error={!hasFinCENId && !!applicantErrors?.firstName}
              helperText={!hasFinCENId && applicantErrors?.firstName?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.middleName`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              size="small"
              label="Middle name"
              error={!hasFinCENId && !!applicantErrors?.middleName}
              helperText={!hasFinCENId && applicantErrors?.middleName?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.suffix`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              size="small"
              label="Suffix"
              error={!hasFinCENId && !!applicantErrors?.suffix}
              helperText={!hasFinCENId && applicantErrors?.suffix?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.dateOfBirth`}
          control={control}
          rules={{ required: !hasFinCENId && "Date of birth is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Date of birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              error={!!applicantErrors?.dateOfBirth}
              helperText={applicantErrors?.dateOfBirth?.message}
            />
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Current address:
        </Typography>

        <Controller
          name={`companyApplicants.${index}.addressType`}
          control={control}
          rules={{ required: !hasFinCENId && "Address type is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!applicantErrors?.addressType}
              disabled={hasFinCENId}
            >
              {/*TODO Mihai: check if is the correct value or label*/}
              <RadioGroup {...field} row content="Address type">
                <FormControlLabel
                  value="residential"
                  control={<Radio size="small" />}
                  label="Residential address"
                />
                <FormControlLabel
                  value="business"
                  control={<Radio size="small" />}
                  label="Business address"
                />
              </RadioGroup>
              {applicantErrors?.addressType && (
                <FormHelperText>{applicantErrors.addressType.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        <Controller
          name={`companyApplicants.${index}.address`}
          control={control}
          rules={{
            required: !hasFinCENId && "Address is required",
            pattern: {
              value: /^[a-zA-Z0-9\s]+$/,
              message: "Only alphanumeric characters and spaces are allowed",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Address (no P.O. Box allowed)"
              placeholder="number, street, and apt. or suite no."
              error={!hasFinCENId && !!applicantErrors?.address}
              helperText={!hasFinCENId && applicantErrors?.address?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.city`}
          control={control}
          rules={{
            required: !hasFinCENId && "City is required",
            pattern: {
              value: /^[a-zA-Z0-9\s]+$/,
              message: "Only alphanumeric characters and spaces are allowed",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="City"
              error={!hasFinCENId && !!applicantErrors?.city}
              helperText={!hasFinCENId && applicantErrors?.city?.message}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.state`}
          control={control}
          rules={{ required: !hasFinCENId && "State is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!applicantErrors?.state}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>State</InputLabel>
              <BOIRFormStatesComponent
                {...field}
                error={!hasFinCENId && !!applicantErrors?.state}
                label="State"
              />
            </FormControl>
          )}
        />

        <Controller
          name={`companyApplicants.${index}.zipCode`}
          control={control}
          rules={{
            required: !hasFinCENId && "ZIP code is required",
            validate: (value) => {
              if (hasFinCENId || !value) return true;
              return /^(\d{5}|\d{9})$/.test(value) || "ZIP code must be 5 or 9 digits";
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              required={!hasFinCENId}
              size="small"
              label="ZIP Code"
              error={!hasFinCENId && !!applicantErrors?.zipCode}
              helperText={!hasFinCENId && applicantErrors?.zipCode?.message}
              inputProps={{
                maxLength: 9,
              }}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                field.onChange(numericValue);
              }}
              inputMode="numeric"
            />
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Form of identification and issuing jurisdiction:
        </Typography>

        <Controller
          name={`companyApplicants.${index}.documentType`}
          control={control}
          rules={{ required: !hasFinCENId && "Document type is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!applicantErrors?.documentType}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>Identifying document type</InputLabel>
              <BOIRFormIdentifyingDocumentComponent {...field} label="Identifying document type" />
            </FormControl>
          )}
        />

        <Controller
          name={`companyApplicants.${index}.documentNumber`}
          control={control}
          rules={{
            required: !hasFinCENId && "Document number is required",
            maxLength: !hasFinCENId
              ? {
                  value: 25,
                  message: "Document number must be 25 characters or less",
                }
              : undefined,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Identifying document number"
              error={!hasFinCENId && !!applicantErrors?.documentNumber}
              helperText={!hasFinCENId && applicantErrors?.documentNumber?.message}
              inputProps={{
                maxLength: 25,
              }}
            />
          )}
        />

        <Controller
          name={`companyApplicants.${index}.documentCountry`}
          control={control}
          rules={{ required: !hasFinCENId && "Document country is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              size="small"
              error={!hasFinCENId && !!applicantErrors?.documentCountry}
            >
              <InputLabel>Country/Jurisdiction</InputLabel>
              <Select
                {...field}
                value={field.value || ""}
                label="Country/Jurisdiction"
                disabled={hasFinCENId}
              >
                <MenuItem value="US">United States</MenuItem>
              </Select>
              {applicantErrors?.documentCountry && (
                <FormHelperText>{applicantErrors.documentCountry.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        <Controller
          name={`companyApplicants.${index}.documentState`}
          control={control}
          rules={{ required: !hasFinCENId && "Document state is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!applicantErrors?.documentState}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>State</InputLabel>
              <BOIRFormStatesComponent
                {...field}
                error={!hasFinCENId && !!applicantErrors?.documentState}
                size="small"
                label="State"
              />
            </FormControl>
          )}
        />
        <Controller
          name={`companyApplicants.${index}.document`}
          control={control}
          rules={{ required: !hasFinCENId && "Document is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              size="small"
              error={!hasFinCENId && !!applicantErrors?.document}
              disabled={hasFinCENId}
            >
              <InputLabel>Identifying document image</InputLabel>
              <FileDropzone
                files={documentFiles}
                setFiles={(newFiles) => field.onChange(newFiles)}
                onFileChange={handleFileChange}
                acceptedFiles={ACCEPTED_FILE_TYPES_ATTACHMENTS}
                displayMessage={"Accepted files: PDF, JPG, JPEG, PNG (max 4MB)"}
                disabled={hasFinCENId}
              />
              {applicantErrors?.document && (
                <FormHelperText>{applicantErrors.document.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </FormGroup>
    </Box>
  );
};
