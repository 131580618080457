import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { auth, db } from "../../auth/auth";
import { format } from "date-fns";

interface AuditAction {
  timestamp: string;
  action: string;
  metadata?: Record<string, any>;
}

export const FilingActions = () => {
  const [actions, setActions] = useState<AuditAction[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);

  const fetchAuditHistory = useCallback(async () => {
    setLoading(true);
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) return;

      const auditRef = collection(db, "audit");

      const q = query(auditRef, where("uid", "==", firebaseUser.uid), orderBy("timestamp", "desc"));

      const querySnapshot = await getDocs(q);
      const auditActions: AuditAction[] = [];
      setCount(querySnapshot.size);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        auditActions.push({
          timestamp: data.timestamp,
          action: data.action,
          metadata: data.metadata,
        });
      });

      setActions(auditActions);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAuditHistory();
  }, [fetchAuditHistory]);

  const handleChangePage = async (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Filing Actions History
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={2} align="center" sx={{ py: 3 }}>
                    <CircularProgress size={40} />
                  </TableCell>
                </TableRow>
              ) : (
                actions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((action, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        {format(new Date(action.timestamp), "MMM dd, yyyy HH:mm:ss")}
                      </TableCell>
                      <TableCell>{action.action}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
