import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fincenSelectors } from "../../../../features/fincenData/fincenSelector";

interface FormSubmissionProps {
  onSubmit: () => void;
}

export const FormSubmission: React.FC<FormSubmissionProps> = ({ onSubmit }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initiateSubmissionStatus = useSelector(fincenSelectors.initiateSubmissionStatus);

  const handleSubmit = (): void => {
    if (!isChecked) {
      setError("Please certify the information before submitting");
      return;
    }
    setError(null);
    onSubmit();
  };

  return (
    <Box sx={{ padding: 4, position: "relative" }}>
      <Typography variant="h4" gutterBottom textAlign="center">
        Submit Your Beneficial Ownership Information Report (BOIR)
      </Typography>

      <Box
        sx={{
          mt: 4,
          p: 3,
          border: "1px solid #e0e0e0",
          borderRadius: 2,
          backgroundColor: "#f8f8f8",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsChecked(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body1">
              I certify that I am authorized to file this BOIR on behalf of the reporting company
              registered by me on the Licenz platform. I further certify, on behalf of the reporting
              company, that the information contained in this BOIR is true, correct and complete.
            </Typography>
          }
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={initiateSubmissionStatus !== "idle"}
            sx={{
              minWidth: 200,
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
          >
            Submit BOIR
            {initiateSubmissionStatus !== "idle" && <CircularProgress size={20} color="inherit" />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
