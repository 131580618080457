export type FileTypes = {
  [key: string]: string[];
};

export const ACCEPTED_FILE_TYPES_ATTACHMENTS: FileTypes = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
};

export const ACCEPTED_FILE_TYPES_UPLOAD: FileTypes = {
  "application/zip": [".zip"],
  "application/octet-stream": [".BOIR"],
};
