import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from "../../auth/auth";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import User from "../../models/user/User";

const initialState = {
  user: new User("", false, false, "", "", "", "").formatForState(),
  auth: {
    isAuthenticated: false,
    loading: true,
    emailVerified: false,
    idToken: null as string | null,
    error: null as string | null,
  },
};

export const initializeAuth = createAsyncThunk("user/initializeAuth", async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      //if user's token is expired, re-authenticate
      const tokenResult = await user.getIdTokenResult();
      const isTokenExpired = new Date().getTime() > new Date(tokenResult.expirationTime).getTime();
      const token = isTokenExpired ? await user.getIdToken(true) : tokenResult.token;

      return {
        isAuthenticated: user.emailVerified,
        emailVerified: user.emailVerified,
        idToken: token,
        error: null,
      };
    } catch (error: any) {
      return {
        isAuthenticated: false,
        emailVerified: false,
        idToken: null,
        error: error.message,
      };
    }
  }
  return {
    isAuthenticated: false,
    emailVerified: false,
    idToken: null,
    error: null,
  };
});

export const loginUser = createAsyncThunk(
  "user/login",
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Add timeout to ensure Firebase connection is established
      // eslint-disable-next-line no-async-promise-executor
      const tokenPromise = new Promise(async (resolve, reject) => {
        try {
          const token = await userCredential.user.getIdToken();
          resolve({
            isAuthenticated: userCredential.user.emailVerified,
            emailVerified: userCredential.user.emailVerified,
            idToken: token,
          });
        } catch (error) {
          reject(error);
        }
      });

      // Add 10 second timeout
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error("Network request timeout")), 10000);
      });

      return await Promise.race([tokenPromise, timeoutPromise]);
    } catch (error: any) {
      if (error.code === "auth/network-request-failed") {
        return rejectWithValue("Network error. Please check your connection and try again.");
      }
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk("user/logout", async (_, { rejectWithValue }) => {
  try {
    await signOut(auth);
    localStorage.clear();
    return null;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const updatedUser = new User(
        action.payload.firebaseUid,
        action.payload.hasCompletedSurvey,
        action.payload.payment_complete,
        action.payload.firstName,
        action.payload.lastName,
        action.payload.email,
        action.payload.phoneNumber
      );
      state.user = updatedUser.formatForState();
    },
    updateAuthState: (state, action) => {
      state.auth = {
        ...action.payload,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize Auth
      .addCase(initializeAuth.pending, (state) => {
        state.auth.loading = true;
      })
      .addCase(initializeAuth.fulfilled, (state, action) => {
        state.auth = {
          ...action.payload,
          loading: false,
        };
      })
      // Login
      .addCase(loginUser.pending, (state) => {
        state.auth.loading = true;
        state.auth.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const payload = action.payload as {
          isAuthenticated: boolean;
          emailVerified: boolean;
          idToken: string | null;
          error?: string | null;
        };

        state.auth = {
          ...payload,
          loading: false,
          error: null,
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.auth = {
          isAuthenticated: false,
          loading: false,
          emailVerified: false,
          idToken: null,
          error: action.payload as string | null,
        };
      })
      // Logout
      .addCase(logoutUser.fulfilled, (state) => {
        state.auth = {
          isAuthenticated: false,
          loading: false,
          emailVerified: false,
          idToken: null,
          error: null,
        };
        state.user = new User("", false, false, "", "", "", "").formatForState();
      });
  },
});

export const { updateUser, updateAuthState } = userSlice.actions;
export default userSlice.reducer;
