import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FileDropzone } from "./FileDropzone";
import { ACCEPTED_FILE_TYPES_UPLOAD } from "../constants/acceptedFileTypes";
import { uploadAndDecodeBOIRFile } from "../pages/home/components/boir-form/utils";
import { BOIRFormData } from "../pages/home/components/boir-form/BOIRFormDefaults";

interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: (formData: BOIRFormData) => void;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({ open, onClose, onComplete }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileChange = (uploadedFiles: File[] | null) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setFiles(uploadedFiles);
      setErrorMessage(null);
    } else {
      setFiles([]);
    }
  };

  const handleOnClose = () => {
    setFiles([]);
    setErrorMessage(null);
    onClose();
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      try {
        const formData = await uploadAndDecodeBOIRFile(files[0]);
        if (onComplete) {
          onComplete(formData);
        }
        onClose();
      } catch (error: any) {
        console.error("Error decoding file:", error);
        setErrorMessage(error.message);
        setFiles([]);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Upload your BOI file
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileDropzone
          files={files}
          setFiles={setFiles}
          onFileChange={handleFileChange}
          acceptedFiles={ACCEPTED_FILE_TYPES_UPLOAD}
          displayMessage={"Accepted files: ZIP, BOIR (max 50MB)"}
          maxSize={50 * 1024 * 1024} // 50MB
        />
        {errorMessage && (
          <Typography color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
        <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
          <Button onClick={handleOnClose} variant="outlined" style={{ marginRight: "8px" }}>
            Cancel
          </Button>
          <Button onClick={handleUpload} variant="contained" disabled={files.length === 0}>
            Upload
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
