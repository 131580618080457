import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, Upload as UploadIcon } from "@mui/icons-material";
import { ErrorCode, useDropzone } from "react-dropzone";
import { FileTypes } from "../constants/acceptedFileTypes";

type FileDropzoneProps = {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  onFileChange: (files: File[] | null) => void;
  acceptedFiles: FileTypes;
  displayMessage: string;
  maxFiles?: number;
  maxSize?: number;
  disabled?: boolean;
};

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  files,
  setFiles,
  onFileChange,
  acceptedFiles,
  displayMessage,
  maxFiles = 1,
  maxSize = 4194304, // 4MB
  disabled = false,
}) => {
  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (files) {
        if (files.length + acceptedFiles.length > maxFiles) {
          setError(`You can only upload up to ${maxFiles} files`);
          return;
        }
        setFiles(() => [...acceptedFiles]);
        onFileChange(acceptedFiles);
        setError(null);
      }
    },
    [files, maxFiles, setFiles, onFileChange]
  );

  const removeFile = (fileToRemove: File) => {
    setFiles(files.filter((file) => file !== fileToRemove));
    onFileChange(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxSize: maxSize,
    maxFiles: maxFiles,
    onDropRejected: (fileRejections) => {
      if (fileRejections[0].errors[0].code === ErrorCode.FileInvalidType) {
        setError(
          "Invalid file type. Type must be one of the following: " +
            Object.values(acceptedFiles)
              .map((file) => {
                if (Array.isArray(file)) {
                  return file.join(", ");
                }
                return file;
              })
              .join(", ")
        );
      } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
        setError(`File is larger than ${maxSize / 1024 / 1024}MB`);
      } else if (fileRejections[0].errors[0].code === ErrorCode.TooManyFiles) {
        setError(`You can only upload up to ${maxFiles} files`);
      }
    },
    onDropAccepted: () => {
      setError(null);
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed",
          borderColor: isDragActive ? "primary.main" : "grey.300",
          borderRadius: 2,
          p: 3,
          mb: 2,
          bgcolor: isDragActive ? "action.hover" : "background.paper",
          cursor: !disabled ? "pointer" : "default",
          transition: "all 0.2s ease",
          "&:hover": !disabled
            ? {
                borderColor: "primary.main",
                bgcolor: "action.hover",
              }
            : {},
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input {...getInputProps()} disabled={disabled} />
        <UploadIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
        <Typography variant="body1" color="textSecondary" align="center">
          {isDragActive
            ? "Drop the files here..."
            : "Drag & drop files here, or click to select files"}
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center" sx={{ mt: 1 }}>
          {displayMessage}
        </Typography>
        {error && (
          <Typography variant="caption" color="error" align="center" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </Box>

      {files && files.length > 0 && (
        <List>
          {files.map((file, index) => (
            <ListItem
              key={index}
              sx={{
                border: "1px solid",
                borderColor: "grey.200",
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemText
                primary={file.name}
                secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeFile(file)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
