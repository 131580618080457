import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../auth/auth";

export enum AuditActionType {
  BOIR_SUBMITTED = "BOIR_SUBMITTED",
  BOIR_FILING_DATA_DOWNLOAD = "BOIR_FILING_DATA_DOWNLOAD",
  BOIR_FILING_DATA_UPLOAD = "BOIR_FILING_DATA_UPLOAD",
}
export type AuditAction = {
  uid: string;
  timestamp: string;
  action: AuditActionType;
  metadata?: Record<string, any>;
};

export const logAuditAction = async (action: AuditActionType, metadata?: Record<string, any>) => {
  const firebaseUser = auth.currentUser;
  if (!firebaseUser) return;

  const auditAction: AuditAction = {
    uid: firebaseUser.uid,
    timestamp: new Date().toISOString(),
    action,
    ...(metadata && { metadata }),
  };

  addDoc(collection(db, "audit"), auditAction);
};
