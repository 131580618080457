import React, { useState } from "react";
import { Alert, Card, CardContent, CardHeader, Link, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { logoutUser } from "../../../features/user/userSlice";
import { SignUp } from "../../userauth/SignUp";
import TermsAndConditions from "./TermsAndConditions";

export const SignUpSignInSlide = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user.auth);
  const [signOutNotice, setSignOutNotice] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleLogout = async (): Promise<void> => {
    const success = await dispatch(logoutUser());

    if (success) {
      setSignOutNotice("");
    } else {
      setSignOutNotice("Unable to sign out, please try again");
    }
  };

  const handleAcceptTerms = () => {
    setShowTerms(false);
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
    >
      {!isAuthenticated ? (
        <>{showTerms ? <TermsAndConditions onAccept={handleAcceptTerms} /> : <SignUp />}</>
      ) : (
        <Card>
          <CardHeader title="Success" />
          <CardContent>
            You have successfully signed up, continue on or{" "}
            <Link style={{ cursor: "pointer" }} onClick={() => handleLogout()}>
              log out here
            </Link>
            {signOutNotice && (
              <Alert severity="warning" sx={{ marginTop: 2 }}>
                {signOutNotice}
              </Alert>
            )}
          </CardContent>
          <CardHeader />
        </Card>
      )}
    </Stack>
  );
};

export default SignUpSignInSlide;
