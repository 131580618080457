export const exemptionDetails = [
  {
    title: "Large Operating Company",
    conditions: [
      "(a) The entity employs more than 20 full-time employees.",
      "(b) More than 20 full-time employees are employed in the United States.",
      "(c) The entity regularly conducts its business at a physical location in the United States that the entity owns or leases.",
      "(d) The entity filed a Federal income tax return in the United States for the previous year demonstrating more than $5,000,000 in gross receipts or sales.",
    ],
  },
  {
    title: "Inactive Entity",
    conditions: [
      "(a) The entity was in existence on or before January 1, 2020.",
      "(b) The entity is not engaged in any active business.",
      "(c) No 'foreign person' has any ownership interest in the entity.",
      "(d) There has been no change in ownership during the past twelve months.",
      "(e) No funds greater than $1,000 were received by the entity in the past twelve months.",
      "(f) The entity does not own or hold any assets.",
    ],
  },
];
