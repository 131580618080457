import { useEffect } from "react";
import { Alert, Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch, useSelector } from "react-redux";
import { fincenSelectors } from "../../../../features/fincenData/fincenSelector";
import {
  fetchSubmissionStatus,
  OperationStatus,
} from "../../../../features/fincenData/fincenSlice";
import { AppDispatch } from "../../../../app/store";
import { RootState } from "../../../../app/store";
import { SubmissionStatus } from "../../../../features/fincenData/fincenSlice";
import { BOIRFormData } from "../boir-form/BOIRFormDefaults";
import { SubmissionStatusEmailSend } from "./SubmissionStatusEmailSend";

export const SubmissionStatusDisplay = ({ boirFormData }: { boirFormData: BOIRFormData }) => {
  const dispatch = useDispatch<AppDispatch>();

  const submissionData = useSelector(fincenSelectors.submissionData);
  const authorizationStatus = useSelector(fincenSelectors.authorizationStatus);
  const initiateSubmissionStatus = useSelector(fincenSelectors.initiateSubmissionStatus);
  const submissionError = useSelector(
    (state: RootState) => fincenSelectors.submissionError?.(state) ?? ""
  );
  const xmlData = useSelector((state: RootState) => fincenSelectors.xmlData?.(state) ?? "");

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchSubmissionStatus());
    }, 4000);

    if (
      authorizationStatus === OperationStatus.FAILED ||
      initiateSubmissionStatus === OperationStatus.FAILED ||
      submissionData?.status.submissionStatus === SubmissionStatus.ACCEPTED ||
      submissionData?.status.submissionStatus === SubmissionStatus.REJECTED ||
      submissionData?.status.submissionStatus === SubmissionStatus.VALIDATION_FAILED
    ) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    dispatch,
    submissionData?.status.submissionStatus,
    authorizationStatus,
    initiateSubmissionStatus,
  ]);

  let pdfUrl = submissionData?.pdfBinary;

  //We transform the pdfBinary which is a base64 string into a url
  if (pdfUrl) {
    //we decode the base64 string into a blob and then create a url from that blob
    const decodedPdf = atob(pdfUrl);
    const pdfBytes = new Uint8Array(decodedPdf.length);
    for (let i = 0; i < decodedPdf.length; i++) {
      pdfBytes[i] = decodedPdf.charCodeAt(i);
    }
    pdfUrl = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
  }

  const status = submissionData?.status.submissionStatus;
  const isStatusAccepted = status === SubmissionStatus.ACCEPTED;
  const isStatusRejected = status === SubmissionStatus.REJECTED;

  console.log("submissionData", submissionData);
  console.log("xmlData", xmlData);

  const renderContent = () => {
    if (
      authorizationStatus === OperationStatus.FAILED ||
      initiateSubmissionStatus === OperationStatus.FAILED
    ) {
      return (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            An error occurred during submission.
          </Alert>
          <Typography variant="body2" color="text.secondary">
            Please review your submission details and try again. Contact us at{" "}
            <a href="mailto:info@lincenz.com">info@lincenz.com</a> if the problem persists.
          </Typography>
        </Box>
      );
    }
    switch (status) {
      case SubmissionStatus.INITIATED:
      case SubmissionStatus.PROCESSING:
      case SubmissionStatus.VALIDATION_PASSED:
        return (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <CircularProgress size={40} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {status === SubmissionStatus.INITIATED && "Initiating Submission..."}
              {status === SubmissionStatus.PROCESSING && "Processing Your Submission..."}
              {status === SubmissionStatus.VALIDATION_PASSED && "Validation Passed - Finalizing..."}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {`This may take a few minutes. Please don't close this window.`}
            </Typography>
          </Box>
        );

      case SubmissionStatus.ACCEPTED:
      case SubmissionStatus.REJECTED:
        return (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <PictureAsPdfIcon
              sx={{
                fontSize: 48,
                color: isStatusAccepted ? "success.main" : "error.main",
              }}
            />
            <Typography
              variant="h6"
              color={isStatusAccepted ? "success.main" : "error.main"}
              sx={{ mt: 2 }}
            >
              {isStatusAccepted && "Submission Accepted!"}
              {isStatusRejected && "Submission Rejected!"}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {isStatusAccepted && "Your BOIR submission has been successfully accepted by FinCEN."}
              {isStatusRejected && "Your BOIR submission has been rejected by FinCEN."}
            </Typography>
            {pdfUrl && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Click below to view your submission transcript:
                </Typography>
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                  View Transcript
                </a>
              </Box>
            )}
            <Typography variant="body1" color="text.secondary" gutterBottom sx={{ mt: 4 }}>
              {"Fill out the form below to send your BOIR submission tracking ID to your email."}
            </Typography>
            <SubmissionStatusEmailSend
              boirFormData={boirFormData}
              pdfBinary={submissionData?.pdfBinary}
            />
          </Box>
        );

      case SubmissionStatus.VALIDATION_FAILED:
      case SubmissionStatus.FAILED:
        return (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Alert severity="error" sx={{ mb: 2 }}>
              {status === SubmissionStatus.VALIDATION_FAILED && "Validation Failed: "}
              {status === SubmissionStatus.FAILED && "Submission Failed: "}
              {submissionError || "An error occurred during submission"}
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Please review your submission details and try again. Contact support if the problem
              persists.
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: 4, position: "relative" }}>
      <Typography variant="h4" gutterBottom textAlign="center">
        Submission Status
      </Typography>

      <Card sx={{ mt: 4 }}>
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </Box>
  );
};
