import React, { useCallback } from "react";
import { EmbeddedCheckout, EmbeddedCheckoutProvider, useStripe } from "@stripe/react-stripe-js";
import { stripePrices } from "./config/stripe-config";
import useApi from "../../api/useApi";

interface StripePaymentComponentProps {
  onComplete: () => void;
}

export const StripePaymentComponent: React.FC<StripePaymentComponentProps> = ({ onComplete }) => {
  const stripe = useStripe();
  const { post } = useApi();

  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session

    const data = await post(
      `${process.env.REACT_APP_FINSEND_API}/stripe/create-checkout-session`,
      {
        priceId: stripePrices.single_owner,
      },
      {
        "Content-Type": "application/json",
      }
    );
    return data.clientSecret;
  }, [post]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripe}
        options={{
          ...options,
          onComplete: handleComplete,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
