import { styled } from "@mui/material/styles";
import { Accordion, AccordionProps } from "@mui/material";

interface StyledAccordionProps extends AccordionProps {
  error?: boolean;
}

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== "error",
})<StyledAccordionProps>(({ theme, error }) => ({
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  "&:before": {
    display: "none",
  },
  margin: "8px 0",
  borderRadius: "4px",
  border: error ? `1px solid ${theme.palette.error.main}` : "none",
  "&:first-of-type": {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  "&.error": {
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.Mui-disabled .MuiAccordionSummary-root": {
    opacity: 0.7,
  },
}));
