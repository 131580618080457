import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredFormLabel } from "../../../../components/RequiredFormLabel";
import { BOIRFormData } from "./BOIRFormDefaults";
import React from "react";

export const BOIRFormFilingInformation: React.FC = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BOIRFormData>();

  const typeOfFiling = watch("filingInformation.typeOfFiling");

  return (
    <Box sx={{ p: 1 }}>
      <FormGroup sx={{ gap: 1 }}>
        <FormControl error={!!errors.filingInformation?.typeOfFiling}>
          <RequiredFormLabel required id="type-of-filing-label">
            Type of filing:
          </RequiredFormLabel>
          <Controller
            name="filingInformation.typeOfFiling"
            control={control}
            defaultValue="initial"
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value="initial"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center" gap={0.5}>
                      Initial report
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Select this if this is the first BOIR filed for your reporting company.
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="correct"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center" gap={0.5}>
                      Correct prior report
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Select this if the report corrects inaccurate information from a
                            previously filed BOIR.
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="update"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center" gap={0.5}>
                      Update prior report
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Select this if the report updates information from a previously filed
                            BOIR; for example, if the report updates a previously filed BOI report,
                            to include one or more new beneficial owners.
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="exempt"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center" gap={0.5}>
                      Newly Exempt entity
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Select this after having filed a BOIR, the reporting company is now
                            exempt from BOI reporting requirements.
                            <br />
                            e.g., the company grows to more than 20 US employees on a full-time
                            basis and filed in the previous year a Federal income tax return
                            demonstrating more than $5,000,000 in sales.
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            )}
          />
        </FormControl>

        {typeOfFiling !== "initial" && (
          <Box>
            <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
              Reporting Company information associated with most recent report, if any:
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Controller
                name="filingInformation.legalName"
                control={control}
                rules={{
                  required: "Legal name is required",
                  maxLength: {
                    value: 150,
                    message: "Legal name must be less than 150 characters",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Legal name"
                    size="small"
                    variant="outlined"
                    required
                    error={!!errors.filingInformation?.legalName}
                    helperText={errors.filingInformation?.legalName?.message}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.filingInformation?.taxIdType}>
              <Box display="flex" alignItems="center" gap={0.5}>
                <RequiredFormLabel required>Tax Identification type</RequiredFormLabel>
                <Tooltip
                  title={
                    <Typography variant="body2">
                      Tax identification type: Select &#39;EIN&#39; if the reporting company has a
                      U.S. Employer Identification Number (EIN).
                      <br />
                      Select &#39;SSN-ITIN&#39; if the reporting company utilizes a U.S. Social
                      Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).
                    </Typography>
                  }
                  placement="top"
                  arrow
                  sx={{ cursor: "pointer" }}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Box>
              <Controller
                name="filingInformation.taxIdType"
                control={control}
                rules={{
                  required: "Tax ID type is required",
                }}
                render={({ field }) => (
                  <>
                    <Select {...field} size="small">
                      <MenuItem value="2">EIN</MenuItem>
                      <MenuItem value="1">SSN/ITIN</MenuItem>
                      {/* <MenuItem value="9">Foreign</MenuItem> */}
                    </Select>
                    <FormHelperText sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
                      If you have a Foreign Tax ID, please contact us at:{" "}
                      <a href="mailto:info@licenz.com">info@licenz.com</a>
                    </FormHelperText>
                  </>
                )}
              />
              {errors.filingInformation?.taxIdType && (
                <FormHelperText>{errors.filingInformation.taxIdType.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={!!errors.filingInformation?.taxIdNumber}>
              <Controller
                name="filingInformation.taxIdNumber"
                control={control}
                rules={{
                  required: "Tax ID number is required",
                  pattern: {
                    value: /^[0-9-]+$/,
                    message: "Please enter a valid tax ID number",
                  },
                  validate: (value) => {
                    if (value.replace(/\D/g, "").length !== 9) {
                      return "Tax ID number must be 9 digits long";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Tax Identification number"
                    required
                    size="small"
                    error={!!errors.filingInformation?.taxIdNumber}
                    helperText={errors.filingInformation?.taxIdNumber?.message}
                    inputProps={{
                      maxLength: 9,
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      field.onChange(value.slice(0, 9));
                    }}
                  />
                )}
              />
            </FormControl>

            {/*<FormControl fullWidth sx={{ mb: 2 }}>
              <Controller
                name="filingInformation.jurisdiction"
                control={control}
                rules={{
                  required:
                    watch("filingInformation.taxIdType") === "Foreign"
                      ? "Jurisdiction is required for foreign tax IDs"
                      : false,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Country/Jurisdiction"
                    helperText={
                      errors.filingInformation?.jurisdiction?.message || "(if foreign tax ID only)"
                    }
                    variant="outlined"
                    required={watch("filingInformation.taxIdType") === "Foreign"}
                    error={!!errors.filingInformation?.jurisdiction}
                    disabled={watch("filingInformation.taxIdType") !== "Foreign"}
                  />
                )}
              />
            </FormControl>*/}
          </Box>
        )}
        <FormControl fullWidth>
          <Controller
            name="filingInformation.datePrepared"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date prepared"
                variant="outlined"
                size="small"
                disabled
                value={new Date().toLocaleDateString()}
              />
            )}
          />
        </FormControl>
      </FormGroup>
    </Box>
  );
};
