import { Box, FormControl, FormGroup, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { BOIRFormData } from "./BOIRFormDefaults";

export const BOIRFormSubmitterInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BOIRFormData>();
  return (
    <Box sx={{ p: 1 }}>
      <FormGroup sx={{ gap: 1 }}>
        <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
          Submitter information for the FinCEN filing:
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Controller
            name="submitterInfo.firstName"
            control={control}
            rules={{
              required: "First name is required",
              maxLength: {
                value: 150,
                message: "First name must be less than 150 characters",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="First name"
                size="small"
                variant="outlined"
                required
                error={!!errors.submitterInfo?.firstName}
                helperText={errors.submitterInfo?.firstName?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Controller
            name="submitterInfo.lastName"
            control={control}
            rules={{
              required: "Last name is required",
              maxLength: {
                value: 150,
                message: "Last name must be less than 150 characters",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last name"
                size="small"
                variant="outlined"
                required
                error={!!errors.submitterInfo?.lastName}
                helperText={errors.submitterInfo?.lastName?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Controller
            name="submitterInfo.email"
            control={control}
            rules={{
              required: "Email is required",
              maxLength: {
                value: 150,
                message: "Email must be less than 150 characters",
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                size="small"
                variant="outlined"
                required
                error={!!errors.submitterInfo?.email}
                helperText={errors.submitterInfo?.email?.message}
              />
            )}
          />
        </FormControl>
      </FormGroup>
    </Box>
  );
};
