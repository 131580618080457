import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DEFAULT_MESSAGE, NO, questions, YES } from "./components/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInstance } from "../../../../features/user/userSelector";
import { updateUser } from "../../../../features/user/userSlice";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { BoirFilingChecklistStepper } from "./components/BOIRFilingChecklistStepper";
import { exemptionDetails } from "../../../../constants/exemptionDetails";
import { UploadFileModal } from "../../../../components/UploadFileModal";
import { RootState } from "../../../../app/store";
import { BOIRFormData } from "../boir-form/BOIRFormDefaults";
import { AuditActionType, logAuditAction } from "../../../../services/auditService";

type SurveyProps = {
  setActiveStep: (step: number) => void;
  setBoirForm: (formData: BOIRFormData) => void;
};

type SurveyData = {
  [key: number]: string;
};

export const Survey: React.FC<SurveyProps> = ({ setActiveStep, setBoirForm }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInstance);
  const { isAuthenticated } = useSelector((state: RootState) => state.user.auth);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
    user.hasCompletedSurvey ? questions.length - 1 : 0
  );
  const [message, setMessage] = useState<string>(user.hasCompletedSurvey ? DEFAULT_MESSAGE : "");
  const [localSurveyData, setLocalSurveyData] = useState<SurveyData>({});
  const [boirStepperCompleted, setBoirStepperCompleted] = useState<boolean>(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

  // Save survey response to localStorage
  const saveSurveyToLocalStorage = (answer: string, questionIndex: number) => {
    const updatedSurveyData = {
      ...localSurveyData,
      [questionIndex]: answer,
    };
    setLocalSurveyData(updatedSurveyData);
    localStorage.setItem("surveyData", JSON.stringify(updatedSurveyData));
  };

  const resetSurvey = () => {
    setCurrentQuestionIndex(0);
    setSelectedOption("");
    setMessage("");
    setExpanded(false);
    dispatch(
      updateUser({
        ...user,
        hasCompletedSurvey: false,
      })
    );
    localStorage.removeItem("surveyData");
    setLocalSurveyData({});
  };

  const handleResponse = (answer: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    if (answer === YES) {
      setMessage(currentQuestion.yesMessage || "");
    } else if (answer === NO) {
      setMessage(currentQuestion.noMessage || "");
    }

    saveSurveyToLocalStorage(answer, currentQuestionIndex);

    if (currentQuestion.correctAnswer != answer) {
      setCurrentQuestionIndex(questions.length);
      dispatch(
        updateUser({
          ...user,
          hasCompletedSurvey: true,
        })
      );
    } else if (currentQuestionIndex < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleCompleteBOI = () => {
    setActiveStep(1);
  };

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleUploadComplete = async (formData: BOIRFormData) => {
    setBoirForm(formData);
    await logAuditAction(AuditActionType.BOIR_FILING_DATA_UPLOAD);
    handleCompleteBOI();
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ flex: 3 }}
      >
        {/* TODO: Will be removed after testing and feedback */}
        {/* <Card sx={{ width: "100%" }}>
          <CardHeader title="Evaluate Your FinCEN Filing Obligations" />
          <CardContent>
            <LinearProgress
              variant="determinate"
              value={message ? 100 : (currentQuestionIndex / questions.length) * 100}
              sx={{ mb: 3 }}
            />
            {!message ? (
              questions.map((q, index) => (
                <div key={index}>
                  {currentQuestionIndex === index && (
                    <Question
                      question={q}
                      handleResponse={handleResponse}
                      selectedOption={localSurveyData[index] || selectedOption} // Load from localStorage if available
                    />
                  )}
                </div>
              ))
            ) : (
              <>
                <Typography paragraph>{message}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button variant="contained" onClick={resetSurvey}>
                    Update Your Answers
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCompleteBOI}
                    disabled={!boirStepperCompleted}
                  >
                    Complete Your BOI Report
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card> */}

        <BoirFilingChecklistStepper onComplete={setBoirStepperCompleted} />

        {/* Understanding Primary Exemptions section */}
        <Card sx={{ width: "100%" }}>
          <CardHeader title="Understanding Primary Exemptions" />
          <CardContent>
            {exemptionDetails.map((exemption, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChangeAccordion(`panel${index}`)}
                key={index}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{exemption.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {exemption.conditions.map((condition, idx) => (
                      <ListItem key={idx}>
                        <ListItemText primary={condition} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    All conditions must be met for this exemption to apply.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card>
      </Stack>

      {/* User Authentication Box */}
      <Box
        sx={{
          flex: 1,
          maxWidth: "200px",
          p: 2,
          backgroundColor: "#e3f2fd",
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ml: 2,
        }}
      >
        <PersonIcon sx={{ fontSize: 40, color: "primary.main", mb: 1 }} />
        {isAuthenticated ? (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Welcome Back!
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              If you still need to complete your BOI Report, please access the online form provided
              below.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => setActiveStep(1)}
              disabled={!boirStepperCompleted}
            >
              Complete your BOI report
            </Button>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              If you have already completed your BOI Report, please upload your previous saved{" "}
              <strong>.BOIR</strong> or <strong>BOIR.zip</strong> file below.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                setShowUploadFileModal(true);
              }}
            >
              Upload your BOI form
            </Button>
            <UploadFileModal
              open={showUploadFileModal}
              onClose={() => setShowUploadFileModal(false)}
              onComplete={handleUploadComplete}
            />
          </>
        ) : (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Already Have an Account?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Sign in and submit your BOI Report
            </Typography>
            <Button variant="contained" component={Link} to="/sign-in" sx={{ mt: 2 }}>
              Sign In
            </Button>
            <Typography variant="body1" align="center" gutterBottom sx={{ mt: 2 }}>
              Don&#39;t have an account? Sign up here.
            </Typography>
            <Button variant="contained" component={Link} to="/sign-up" sx={{ mt: 2 }}>
              Sign Up
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
